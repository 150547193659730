import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { dataURItoBlob } from "../global/FileData";
import {
  cutHtmlTextContent,
  cutLexicalString,
  getCookie,
  htmlEscape,
  isMobile,
  links2html,
  mail2html,
} from "../global/Global";
import ImageZoom from "./ImageZoom";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "./Login";
const DOMPurify = require("dompurify")(window);

const parsePost = (text) => {
  if (!text) return;
  // FIXME: too much performace usage for mail
  // text = mail2html(text);
  text = links2html(text);
  return text;
};

const articleObject = (potencialObj) => {
  if (potencialObj.length < 3) return;

  if (potencialObj[0] != "{" && potencialObj[potencialObj.length - 1] != "}")
    return;

  let obj;
  try {
    obj = JSON.parse(potencialObj);
  } catch (e) {
    return;
  }

  if (!obj.blocks) return;

  return obj;
};

const VideoBlock = ({ block, userid }) => {
  const videoRef = useRef();
  const gifSickerRef = useRef();
  const videoIdRef = useRef(
    (Math.random() + 1).toString(36).substring(7) +
      (Math.random() + 1).toString(36).substring(7),
  );
  const volumeUpdater = useRef(null);
  const { user } = useContext(LoginContext);

  useEffect(() => {
    if (!window.IntersectionObserver) {
      return;
    }

    if (!videoRef?.current) {
      return;
    }

    if (block.type == "animation") {
      return;
    }

    if (!user) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logT("video", "video in view", videoIdRef.current);
            if (user.settings?.videoOnScreenStart && videoRef.current.paused) {
              videoRef.current.play().catch((e) => {
                if (e.name === "NotAllowedError") {
                  logT("video", "hasnt activated yet, ignore");
                }
              });
              logT("video", "video autostart because of setting");
            }
            if (!window.talkvioFocusedPlayers)
              window.talkvioFocusedPlayers = [];
            window.talkvioFocusedPlayers.push(videoRef.current);
            logT(
              "video",
              "add focus player",
              "index",
              window.talkvioFocusedPlayers.length - 1,
              "players",
              window.talkvioFocusedPlayers.length,
            );
          } else {
            logT("video", "video out of view", videoIdRef.current);
            if (window.talkvioFocusedPlayers) {
              let index = window.talkvioFocusedPlayers.indexOf(
                videoRef.current,
              );
              if (index >= 0) {
                window.talkvioFocusedPlayers.splice(index, 1);
                logT(
                  "video",
                  "remove focus player",
                  "index",
                  index,
                  "players",
                  window.talkvioFocusedPlayers.length,
                );
              }
            }
            if (
              user.settings?.videoOutScreenStop ||
              (typeof user.settings?.videoOutScreenStop == "undefined" &&
                isMobile())
            ) {
              if (!videoRef.current.paused) {
                logT("video", "video stop because of setting");
                videoRef.current.pause();
              }
              if (
                user.settings?.videoOnScreenStart &&
                window.talkvioFocusedPlayers?.length > 0 &&
                window.talkvioFocusedPlayers[0].paused
              ) {
                logT("video", "play next focused player");
                window.talkvioFocusedPlayers[0].play().catch((e) => {
                  if (e.name === "NotAllowedError") {
                    logT("video", "hasnt activated yet, ignore");
                  }
                });
              }
            }
          }
        });
      },
      { threshold: [0] },
    );

    observer.observe(videoRef?.current);
    logT("video", "listen visibility of video");

    const videoElement = videoRef.current;

    return () => {
      observer.disconnect();
      logT("video", "stop listen visibility of video");
      if (window.talkvioFocusedPlayers) {
        let index = window.talkvioFocusedPlayers.indexOf(videoElement);
        if (index >= 0) {
          window.talkvioFocusedPlayers.splice(index, 1);
          logT(
            "video",
            "remove old focused player",
            "index",
            index,
            "players",
            window.talkvioFocusedPlayers.length,
          );
        }
      }
    };
  }, [block.processing, block.type]);

  if (block.processing) {
    return (
      <div className="video-preview-processing">
        <img
          src={
            (process.env.NODE_ENV == "production"
              ? ""
              : "http://localhost:9989") +
            "/file/thumbnail/" +
            userid +
            "/" +
            block.file
          }
          alt={"video processing"}
        />
        <div className="processing-text">
          {__("Video in proccessing state, please wait until it finished")}
        </div>
      </div>
    );
  }

  return (
    <>
      {block.type == "animation" ? (
        <div className="gif-sticker" ref={gifSickerRef}>
          GIF
        </div>
      ) : null}
      <video
        className={block.type == "animation" ? "animation" : ""}
        controls={block.type != "animation"}
        loop={block.type == "animation"}
        ref={videoRef}
        poster={
          (process.env.NODE_ENV == "production"
            ? ""
            : "http://localhost:9989") +
          "/file/thumbnail/" +
          userid +
          "/" +
          block.file
        }
        onClick={(e) => {
          if (block.type == "animation") {
            if (videoRef.current.paused) {
              logT("animation", "play on click");
              videoRef.current.play();
            } else {
              logT("animation", "pause on click");
              videoRef.current.pause();
            }
          }
        }}
        onPlay={() => {
          logT("video", "playback started");
          if (window.previusBlockPlayStop) {
            logT("video", "stop previous playback");
            window.previusBlockPlayStop();
          }
          window.previusBlockPlayId = videoIdRef?.current;
          window.previusBlockPlayStop = () => {
            videoRef?.current?.pause();
            delete window.previusBlockPlayId;
          };
          if (gifSickerRef?.current)
            gifSickerRef.current.classList.add("playing");
          if (
            typeof window.videoPlayerVolume != "undefined" &&
            videoRef.current.volume != window.videoPlayerVolume
          ) {
            logT(
              "volume",
              "initial volume",
              window.videoPlayerVolume.toFixed(2),
            );
            videoRef.current.volume = window.videoPlayerVolume;
          }
          if (
            typeof window.videoPlayerVolumeMuted != "undefined" &&
            videoRef.current.muted != window.videoPlayerVolumeMuted
          ) {
            logT("volume", "initial muted", window.videoPlayerVolumeMuted);
            videoRef.current.muted = window.videoPlayerVolumeMuted;
          }
        }}
        onPause={() => {
          logT("video", "playback stoped");
          if (
            window.previusBlockPlayStop &&
            window.previusBlockPlayId === videoIdRef?.current
          ) {
            logT("video", "stoped current player, cleanup id ref");
            delete window.previusBlockPlayStop;
            delete window.previusBlockPlayId;
          }
          if (gifSickerRef?.current)
            gifSickerRef.current.classList.remove("playing");
        }}
        onVolumeChange={() => {
          logT(
            "volume",
            "change volume",
            videoRef.current.volume.toFixed(2),
            "muted",
            videoRef.current.muted,
          );
          if (
            window.videoPlayerVolume != videoRef.current.volume ||
            window.videoPlayerVolumeMuted != videoRef.current.muted
          ) {
            if (volumeUpdater.current) {
              clearTimeout(volumeUpdater.current);
            }
            volumeUpdater.current = setTimeout(async () => {
              const token = getCookie("token");
              const userid = parseInt(getCookie("userid"));
              if (!userid || !token) {
                return;
              }
              const data = await window.TALKVIOAPI("setSettings", {
                userid,
                token,
                settings: {
                  videoVolume: videoRef.current.volume,
                  videoMute: videoRef.current.muted,
                },
                silent: true,
              });
              volumeUpdater.current = null;
            }, 3000);
          }
          window.videoPlayerVolume = videoRef.current.volume;
          window.videoPlayerVolumeMuted = videoRef.current.muted;
        }}
      >
        <source
          src={
            (process.env.NODE_ENV == "production"
              ? ""
              : "http://localhost:9989") +
            "/file/" +
            userid +
            "/" +
            block.file
          }
        />
      </video>
    </>
  );
};

const YoutubeBlock = ({ block, description }) => {
  const [playState, setPlayState] = useState(false);
  const fallbackRef = useRef(false);
  const videoIdRef = useRef(
    (Math.random() + 1).toString(36).substring(7) +
      (Math.random() + 1).toString(36).substring(7),
  );

  useEffect(() => {
    if (playState) {
      if (window.previusBlockPlayStop) {
        logT("youtube", "stop previous playback");
        window.previusBlockPlayStop();
      }
      window.previusBlockPlayId = videoIdRef?.current;
      window.previusBlockPlayStop = () => {
        delete window.previusBlockPlayId;
        setPlayState(false);
      };
    }
  }, [playState]);

  return (
    <>
      {playState ? (
        <iframe
          frameBorder="0"
          allowFullScreen="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          width="100%"
          height="100%"
          src={"https://www.youtube.com/embed/" + block.data + "?autoplay=1"}
        />
      ) : (
        <div
          itemProp="image"
          itemScope
          itemType="http://schema.org/ImageObject"
        >
          <meta
            itemProp="creditText"
            content={
              description ? `youtube video for ${description}` : "youtube video"
            }
          />
          <img
            onClick={() => setPlayState(true)}
            src={`https://i.ytimg.com/vi/${block.data}/maxresdefault.jpg`}
            alt={
              description ? `youtube video for ${description}` : "youtube video"
            }
            itemProp="contentUrl"
            onLoad={(e) => {
              if (
                e.target?.naturalWidth == 120 &&
                e.target?.naturalHeight == 90
              ) {
                if (fallbackRef?.current) {
                  return;
                }
                fallbackRef.current = true;
                logT("youtube", "no maxres for", block.data, "fallback to hq");
                e.target.src = `https://i.ytimg.com/vi/${block.data}/hqdefault.jpg`;
              }
            }}
            onError={(e) => {
              if (!e.target) {
                return;
              }
              if (fallbackRef?.current) {
                return;
              }
              fallbackRef.current = true;
              logT(
                "youtube",
                "no maxres for",
                block.data,
                "fallback to hq with error",
              );
              e.target.src = `https://i.ytimg.com/vi/${block.data}/hqdefault.jpg`;
            }}
          />
          <div
            onClick={() => setPlayState(true)}
            className="youtube-play-background-button"
          ></div>
          <svg
            onClick={() => setPlayState(true)}
            className="youtube-play-button"
            viewBox="0 0 16 16"
          >
            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
          </svg>
        </div>
      )}
    </>
  );
};

const TextBlock = ({
  block,
  index,
  maxTextSize,
  convertToText,
  isTextShowOnClick,
  maxTextShowOnClick,
  sharedObject,
  onRenderFinish,
}) => {
  const blockTextRef = useRef();
  const navigate = useNavigate();

  // parse text
  useEffect(() => {
    if (!blockTextRef?.current) return;

    const users = blockTextRef.current.getElementsByClassName("ask-user");
    if (users?.length > 0) {
      for (let i = 0; i < users.length; i++) {
        let userid = users[i].getElementsByTagName("input")?.[0]?.value;
        if (userid) {
          userid = parseInt(userid);
          users[i].onclick = () => {
            logT("TextBlock", "navigate by click on nick to", "user", userid);
            navigate(`/user/${userid}`);
          };
        }
      }
    }

    if (maxTextSize > 0) {
      // remove quotes
      const quotes =
        blockTextRef.current.getElementsByClassName("message-quote");
      if (quotes?.length > 0) {
        for (const quote of quotes) {
          quote.remove();
        }
      }

      const originalHtml = blockTextRef.current.innerHTML;
      if (!convertToText && !isTextShowOnClick) {
        cutHtmlTextContent(blockTextRef.current, maxTextSize);
      }

      let text = blockTextRef.current.innerText;
      const originalText = text;
      if (text.length > 0) {
        if (convertToText && !isTextShowOnClick) {
          text = cutLexicalString(text, maxTextSize, "...");
        }
        if (sharedObject) {
          if (!sharedObject.textSizes) {
            sharedObject.textSizes = [];
          }
          sharedObject.textSizes[index] = text.length;
          if (maxTextShowOnClick) {
            if (!sharedObject.originalText) {
              sharedObject.originalText = [];
            }
            if (!sharedObject.originalText[index])
              sharedObject.originalText[index] = convertToText
                ? originalText
                : originalHtml;
          }
          let needToRender = true;
          let textSizeSummary = 0;
          for (let i = 0; i < index + 1; i++) {
            textSizeSummary += sharedObject.textSizes[i] || 0;
            if (textSizeSummary >= maxTextSize) break;
          }
          if (index > 0 && textSizeSummary >= maxTextSize) {
            needToRender = false;
          }
          if (!needToRender) {
            logT(
              "article",
              "ignore render block",
              index,
              "because of limit symbols",
              textSizeSummary,
              sharedObject.textSizes,
            );
            blockTextRef.current.style.display = "none";
          } else {
            blockTextRef.current.style.display = "";
          }
        }
      }
      if (convertToText) {
        blockTextRef.current.innerHTML = text;
      }
      if (onRenderFinish && typeof window.articleRender != "undefined") {
        if (--window.articleRender == 0) {
          logT("article", "finish render all articles");
          onRenderFinish();
        }
      }
    }
  }, [block.data]);

  useLayoutEffect(() => {
    if (maxTextSize > 0 && onRenderFinish) {
      if (!window.articleRender) window.articleRender = 0;
      window.articleRender++;
    }
  }, [block.data]);

  useDidUpdateEffect(() => {
    if (!maxTextShowOnClick) return;

    if (!isTextShowOnClick) return;

    if (!sharedObject?.originalText?.[index]) return;

    // unclapse text
    blockTextRef.current.innerHTML = sharedObject.originalText[index];
    blockTextRef.current.style.display = "";
  }, [isTextShowOnClick]);

  return (
    <div
      ref={blockTextRef}
      tabIndex="-1"
      className="article-text"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(parsePost(block.data)) || "",
      }}
    />
  );
};

const Block = ({
  block,
  index,
  userid,
  maxTextSize,
  convertToText,
  isTextShowOnClick,
  maxTextShowOnClick,
  sharedObject,
  description,
  dateline,
  onRenderFinish,
}) => {
  const [visible, setVisible] = useState(false);

  if (block.type == "image" && block.data && !block.imageUrl) {
    block.imageUrl = window.URL.createObjectURL(dataURItoBlob(block.data));
  }

  return (
    <div
      className={
        `block ${block.type}` +
        (block.spoiler ? " spoiler" : "") +
        (block.adult ? " adult" : "") +
        (visible ? " visible" : "")
      }
    >
      <div className="blockcontent">
        {block.type == "text" && (
          <TextBlock
            block={block}
            index={index}
            maxTextSize={maxTextSize}
            isTextShowOnClick={isTextShowOnClick}
            maxTextShowOnClick={maxTextShowOnClick}
            convertToText={convertToText}
            sharedObject={sharedObject}
            onRenderFinish={onRenderFinish}
          />
        )}
        {block.type == "image" && block.file && userid && (
          <ImageZoom
            description={block.description}
            descriptionAlt={
              block.description ||
              (description ? `${description} #${index + 1}` : null)
            }
            width={block.width}
            height={block.height}
            src={
              (process.env.NODE_ENV == "production"
                ? ""
                : "https://talkvio.com") +
              "/file/" +
              userid +
              "/" +
              block.file
            }
          />
        )}
        {block.type == "image" && block.imageUrl && !block.file && (
          <ImageZoom description={block.description} src={block.imageUrl} />
        )}
        {(block.type == "video" || block.type == "animation") &&
          block.file &&
          userid && (
            <div
              className="video-container"
              itemProp="video"
              itemScope
              itemType="http://schema.org/VideoObject"
            >
              <VideoBlock block={block} userid={userid} />
              <meta
                itemProp="thumbnailUrl"
                content={
                  (process.env.NODE_ENV == "production"
                    ? ""
                    : "http://localhost:9989") +
                  "/file/thumbnail/" +
                  userid +
                  "/" +
                  block.file
                }
              />
              {dateline ? (
                <meta
                  itemProp="uploadDate"
                  content={new Date(dateline * 1000).toISOString()}
                />
              ) : null}
              <meta
                itemProp="name"
                content={
                  description
                    ? `${description} video #${index + 1}`
                    : `video block #${index + 1}`
                }
              />
            </div>
          )}
        {block.type == "youtube" && block.data && (
          <div className="youtube-container">
            <YoutubeBlock block={block} description={description} />
          </div>
        )}
        {block.type == "twitch" && block.data && (
          <div className="twitch-container">
            <iframe
              src={`https://player.twitch.tv/?${block.data?.video ? "video=" + block.data?.video : ""}${block.data?.channel ? "channel=" + block.data?.channel : ""}&parent=talkvio.com`}
              width="100%"
              height="100%"
              allowFullScreen="1"
            />
          </div>
        )}
        {block.type == "coub" && block.data && (
          <div className="coub-container">
            <iframe
              src={`https://coub.com/embed/${block.data}`}
              width="100%"
              height="100%"
              allowFullScreen="1"
              allow="encrypted-media;"
            />
          </div>
        )}
        {block.type == "rutube" && block.data && (
          <div className="rutube-container">
            <iframe
              src={`https://rutube.ru/play/embed/${block.data}`}
              width="100%"
              height="100%"
              allowFullScreen="1"
            />
          </div>
        )}
        {block.type == "vk" &&
          block.data &&
          block.data.oid &&
          block.data.id && (
            <div className="vk-container">
              <iframe
                width="853"
                height="480"
                src={`//vk.com/video_ext.php?oid=${block.data.oid}&id=${block.data.id}&hash=118cd8b378ffa95e&hd=1`}
                frameBorder="0"
                allowFullScreen="1"
              />
            </div>
          )}
      </div>
      {!visible && block.adult && !block.spoiler ? (
        <div className="blockAdultButton" onClick={() => setVisible(true)}>
          {__("Show 18+ content")}
        </div>
      ) : null}
      {!visible && block.spoiler && !block.adult ? (
        <div className="blockSpoilerButton" onClick={() => setVisible(true)}>
          {__("Show spoiler")}
        </div>
      ) : null}
      {!visible && block.spoiler && block.adult ? (
        <div
          className="blockAdultSpoilerButton"
          onClick={() => setVisible(true)}
        >
          {__("Show 18+ spoiler")}
        </div>
      ) : null}
    </div>
  );
};

const Article = ({
  data,
  className,
  collapsable = false,
  onUncollapse = null,
  onCollapsed = null,
  uncollapseDefault = false,
  textFullQuote = false,
  onTextFullQuote = null,
  noFirstImageAdult = false,
  onFirstImage = null,
  onFirstTextBlock = null,
  onFirstYoutubeBlock = null,
  onFirstVideoPreviewImage = null,
  filterType = null,
  maxTextSize = null,
  maxTextShowOnClick = false,
  convertToText = false,
  quote = false,
  description = null,
  dateline = null,
  onRenderFinish = null,
}) => {
  // let defaultCollapsed = false;
  // if (collapsable) {
  //     if (data.blocks && Array.isArray(data.blocks)) {
  //         const symbols = data.blocks.reduce((sum, block) => {
  //             if (block.type != 'text') {
  //                 return sum + 500;
  //             } else {
  //                 return sum + block.data?.length;
  //             }
  //         }, 0);
  //         if (symbols > 3000) {
  //             defaultCollapsed = true;
  //         }
  //     }
  // }
  // const [ collapsed, setCollapsed ] = useState(defaultCollapsed);
  const articleBody = useRef();
  let sharedObject = useRef({});
  const [collapsed, setCollapsed] = useState(false);
  const [uncollapse, setUncollapse] = useState(uncollapseDefault || false);
  const [isTextShowOnClick, setTextShowOnClick] = useState(
    textFullQuote || false,
  );

  logT(
    "render",
    "article",
    quote ? "quote" : "post",
    data,
    "collapsed =",
    collapsed,
    "uncollapse =",
    uncollapse,
    "fullQuote =",
    isTextShowOnClick,
  );

  useDidUpdateEffect(() => {
    if (onUncollapse) onUncollapse(uncollapse);
  }, [uncollapse]);

  useDidUpdateEffect(() => {
    if (onCollapsed) onCollapsed();
  }, [collapsed]);

  useDidUpdateEffect(() => {
    if (onTextFullQuote) onTextFullQuote(isTextShowOnClick);
  }, [isTextShowOnClick]);

  const calcArticleCut = () => {
    if (!collapsable) return;
    if (!articleBody?.current) return;

    const articleHeight = articleBody.current.offsetHeight;
    if (articleHeight > 1000) {
      setCollapsed(true);
    }
  };

  useLayoutEffect(() => {
    if (maxTextSize > 0 && onRenderFinish) {
      if (!window.articleRender) window.articleRender = 0;
      window.articleRender++;
    }
  }, []);

  useEffect(() => {
    calcArticleCut();

    if (maxTextSize > 0 && onRenderFinish) {
      if (typeof window.articleRender != "undefined") {
        if (--window.articleRender == 0) {
          logT("article", "finish render all articles");
          onRenderFinish();
        }
      }
    }
  }, []);

  let firstImage = false;
  let firstTextBlock = false;
  let firstYoutubeBlock = false;
  let firstVideoPreviewImage = false;

  let blocks = data?.blocks;

  if (
    filterType &&
    filterType.length > 0 &&
    Array.isArray(blocks) &&
    blocks.length > 0
  ) {
    blocks = blocks.filter((block) => filterType.includes(block.type));
    if (blocks.length == 0) {
      logT("article", "post contain no article");
      return null;
    }
  }
  if (quote && Array.isArray(blocks) && blocks.length > 0) {
    blocks = blocks.filter((block) => block.type == "text");
    if (blocks.length == 0) {
      logT("article", "post contain no article");
      return (
        <div className="no-text">
          <i>Прикрепленная картинка или видео</i>
        </div>
      );
    }
  }

  const ret = (
    <>
      {blocks && Array.isArray(blocks) ? (
        <div
          itemProp="articleBody"
          ref={articleBody}
          className={
            "pagetext article" +
            (className ? ` ${className}` : "") +
            (collapsed && !uncollapse ? " collapsed" : "") +
            (quote ? " quote" : "") +
            (maxTextShowOnClick && maxTextSize && !isTextShowOnClick
              ? " pointer"
              : "")
          }
          onClick={() => {
            if (!maxTextShowOnClick) return;
            if (!maxTextSize) return;
            if (isTextShowOnClick) return;

            logT(
              "article",
              "show full text because of maxTextShowOnClick",
              sharedObject?.current,
            );
            setTextShowOnClick(true);
          }}
        >
          {blocks.map((block, i) => {
            if (
              block.type == "image" &&
              !firstImage &&
              (!noFirstImageAdult || !block.adult)
            ) {
              firstImage =
                "https://talkvio.com" +
                "/file/" +
                data.userid +
                "/" +
                block.file;
            }

            // skip adult or adult block in text view as they converted to button
            if (maxTextSize > 0) {
              if (block.spoiler) {
                return (
                  <div className="block text">
                    <i>(содержит спойлер)</i>
                  </div>
                );
              }
              if (block.adult) {
                return (
                  <div className="block text">
                    <i>(содержит 18+)</i>
                  </div>
                );
              }
            }

            if (block.type == "text" && !firstTextBlock && onFirstTextBlock) {
              firstTextBlock = block.data
                ?.replace(/<div>/g, " ")
                .replace(/<[^>]*>?/gm, "")
                .replace(/&nbsp;/g, " ")
                .replace(/[\u200B-\u200D\uFEFF]/g, "")
                .replace(/\n/g, " ")
                .replace(/  /g, " ")
                .trim();
            }

            if (block.type == "youtube" && !firstYoutubeBlock) {
              firstYoutubeBlock = block.data;
            }

            if (
              (block.type == "video" || block.type == "animation") &&
              !firstVideoPreviewImage
            ) {
              firstVideoPreviewImage =
                "https://talkvio.com" +
                "/file/thumbnail/" +
                data.userid +
                "/" +
                block.file;
            }

            return (
              <Block
                key={i}
                index={i}
                block={block}
                userid={data.userid}
                maxTextSize={maxTextSize}
                isTextShowOnClick={isTextShowOnClick}
                maxTextShowOnClick={maxTextShowOnClick}
                convertToText={convertToText}
                sharedObject={sharedObject?.current}
                description={description}
                dateline={dateline}
                onRenderFinish={onRenderFinish}
              />
            );
          })}
          {collapsed && !uncollapse ? (
            <>
              <div
                className="cut-off"
                onClick={() => setUncollapse(true)}
              ></div>
              <div className="showFullPost" onClick={() => setUncollapse(true)}>
                {__("Show in full")}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );

  if (firstTextBlock && onFirstTextBlock) onFirstTextBlock(firstTextBlock);
  if (firstImage && onFirstImage) onFirstImage(firstImage);
  if (!firstImage && firstYoutubeBlock && onFirstYoutubeBlock)
    onFirstYoutubeBlock(firstYoutubeBlock);
  if (
    onFirstVideoPreviewImage &&
    firstVideoPreviewImage &&
    !firstImage &&
    !firstYoutubeBlock
  )
    onFirstVideoPreviewImage(firstVideoPreviewImage);

  return ret;
};
export default Article;

export { articleObject };
